<template>
  <div class="area-display">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>病区管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-search">
      <div class="left">
        <el-input placeholder="请输入病区名称" v-model="query.title" clearable @clear="getListData"></el-input>
        <el-button type="primary" @click="getListData">搜索</el-button>
      </div>
      <div class="right">
        <el-button type="primary" @click="add">添加病区</el-button>
      </div>
    </div>
    <div class="page-content">
      <el-row>
        <el-table :data="dataList" border style="width: 100%">
          <el-table-column prop="title" label="病区名称" width="180"></el-table-column>
          <el-table-column prop="hospital_title" label="归属医院"></el-table-column>
          <el-table-column label="操作" width="300">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="全局可借时间段设置" placement="top">
                <el-button type="warning" icon="el-icon-time" @click="borrowSetting(scope.row.id)" size="small"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="不可借设备时间段" placement="top">
                <el-button type="warning" icon="el-icon-circle-close" @click="taskSetting(scope.row.id)" size="small"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="到期提醒设置" placement="top">
                <el-button type="warning" icon="el-icon-bell" @click="tipsSetting(scope.row.id)" size="small"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="编辑" placement="top">
                <el-button type="primary" icon="el-icon-edit" @click="editItem(scope.row.id)" size="small"></el-button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="删除" placement="top">
                <el-button type="danger" icon="el-icon-delete" @click="deleteItem(scope.row.id)" size="small"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="table-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-sizes="[30, 100, 200, 400]" :page-size="query.size" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </el-row>
    </div>
    <!-- 全局可借时间设置弹窗 -->
    <el-dialog title="全局可借时间设置" :visible.sync="borrowVisible" width="30%">
      <div class="dialog-content" style="text-align: center;">
        <el-time-select placeholder="起始时间" v-model="borrowForm.start_time" :picker-options="{ start: '00:00', step: '00:05', end: '23:55' }"></el-time-select>
        <el-time-select style="margin-left: 20px;" placeholder="结束时间" v-model="borrowForm.end_time" :picker-options="{ start: '00:05', step: '00:05', end: '23:55', minTime: borrowForm.start_time  }"> </el-time-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitBorrow">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 全局过期提醒时间设置弹窗 -->
    <el-dialog title="到期提醒设置" :visible.sync="tipsVisible" width="30%">
      <div class="dialog-content" style="text-align: center;">
        <el-time-select placeholder="提醒时间" v-model="tipsForm.tips_time" :picker-options="{ start: '00:00', step: '00:05', end: '23:55' }"></el-time-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitTips">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 全局不可借设备时间段设置弹窗 -->
    <el-drawer title="不可借设备时间段" :visible.sync="taskVisible" direction="rtl">
      <div class="add-btn-area">
        <el-button type="primary" @click="addNewTask">添加时间段不可借用商品</el-button>
      </div>
      <div class="task-list">
        <div class="task-item" v-for="item in taskList" :index="item.id" :key="item.id">
          <div class="title">{{ item.title }}</div>
          <div class="time-over">
            {{ moment(item.start_time * 1000).format('YYYY年MM月DD日 HH:mm:ss') }} -- {{ moment(item.end_time * 1000).format('YYYY年MM月DD日 HH:mm:ss') }}
          </div>
          <div class="tools">
            <div class="edit" @click="editTask(item.id)">编辑</div>
            <div class="delete" @click="deleteTaskItem(item.id)">删除</div>
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 添加新任务 -->
    <el-dialog title="添加新任务" :visible.sync="taskAddItemVisible" width="50%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div class="dialog-content" style="text-align: center;">
        <el-form :model="addTaskForm" label-position="top" :rules="addTaskRule" ref="addTaskRef" label-width="auto" class="task-form">
          <el-form-item label="任务标题" prop="title">
            <el-input v-model="addTaskForm.title"></el-input>
          </el-form-item>
          <el-form-item label="开始时间-结束时间" prop="timerange">
            <el-date-picker value-format="timestamp" v-model="addTaskForm.timerange" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00']">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="请勾选本次任务的设备" prop="devcieList">
            <el-checkbox-group v-model="addTaskForm.devcieList">
              <el-checkbox :label="item.id"  v-for="item in deviceList" :key="item.id" :index="item.id">{{item.title}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item>
            <el-button @click="cancleTask('addTaskRef')">取 消</el-button>
            <el-button type="primary" @click="submitAddTask('addTaskRef')">提 交</el-button>
          </el-form-item>

        </el-form>
      </div>
    </el-dialog>
    <!-- 编辑新任务 -->
    <el-dialog title="编辑任务" :visible.sync="taskEditItemVisible" width="50%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div class="dialog-content" style="text-align: center;">
        <el-form :model="editTaskForm" label-position="top" :rules="editTaskRule" ref="editTaskRef" label-width="auto" class="task-form">
          <el-form-item label="任务标题" prop="title">
            <el-input v-model="editTaskForm.title"></el-input>
          </el-form-item>
          <el-form-item label="开始时间-结束时间" prop="timerange">
            <el-date-picker value-format="timestamp" v-model="editTaskForm.timerange" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00']">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="请勾选本次任务的设备" prop="devcieList">
            <el-checkbox-group v-model="editTaskForm.devcieList">
              <el-checkbox :label="item.id"  v-for="item in deviceList" :key="item.id" :index="item.id">{{item.title}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item>
            <el-button @click="cancleEditTask('editTaskRef')">取 消</el-button>
            <el-button type="primary" @click="submitEditTask('editTaskRef')">提 交</el-button>
          </el-form-item>

        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AreaDisplay',
  data () {
    return {
      query: {
        title: '',
        size: 30,
        current: 1,
        total: 0
      },
      dataList: [],
      // 全局可借时间显示和隐藏
      borrowVisible: false,
      // 全局提醒时间显示和隐藏
      tipsVisible: false,
      // 不可借设备时间段显示和隐藏
      taskVisible: false,
      // 添加修改任务弹窗
      taskAddItemVisible: false,
      taskEditItemVisible: false,
      taskList: [],
      borrowForm: {
        id: 0,
        start_time: '',
        end_time: ''
      },
      tipsForm: {
        id: 0,
        tips_time: ''
      },
      deviceList: [],
      areaId: 0,
      addTaskForm: {
        tid: 0,
        title: '',
        timerange: [],
        devcieList: []
      },
      editTaskForm: {
        id: 0,
        title: '',
        timerange: [],
        devcieList: []
      },
      addTaskRule: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      editTaskRule: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getListData()
  },
  methods: {
    async getListData () {
      const { data: res } = await this.$http.get('/area-list', { params: this.query })
      if (res.meta.status === 200) {
        this.dataList = res.data.list
        this.query.current = res.data.current
        this.query.total = res.data.total
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleSizeChange (val) {
      this.query.size = val
    },
    handleCurrentChange (val) {
      this.query.current = val
    },
    add () {
      this.$router.push('/dashboard/area-add')
    },
    editItem (id) {
      this.$router.push('/dashboard/area-edit/' + id)
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('/area/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        await this.getListData()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 全局可借时间段设置
    async borrowSetting (id) {
      const { data: res } = await this.$http.get('/borrow-time', { params: { id: id } })
      if (res.meta.status === 200) {
        this.borrowForm.id = id
        this.borrowForm.start_time = res.data.start_time
        this.borrowForm.end_time = res.data.end_time
        this.borrowVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 提交全局可借时间表单
    async submitBorrow () {
      if (this.borrowForm.start_time === '') {
        this.$message.error('请选择开始时间')
        return false
      }
      if (this.borrowForm.end_time === '') {
        this.$message.error('请选择结束时间')
        return false
      }
      const { data: res } = await this.$http.post('/borrow-time', this.borrowForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.borrowVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 全局到期提醒时间设置
    async tipsSetting (id) {
      const { data: res } = await this.$http.get('/tips-time', { params: { id: id } })
      if (res.meta.status === 200) {
        this.tipsForm.id = id
        this.tipsForm.tips_time = res.data.tips_time
        this.tipsVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 提交全局到期提醒时间表单
    async submitTips () {
      if (this.tipsForm.tips_time === '') {
        this.$message.error('请选择时间')
        return false
      }
      const { data: res } = await this.$http.post('/tips-time', this.tipsForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.tipsVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 全局不可借设备时间段设置
    async taskSetting (id) {
      // 获取已设置信息
      const { data: res } = await this.$http.get('area-task-list', { params: { id: id } })
      if (res.meta.status === 200) {
        this.taskList = res.data
        this.addTaskForm.tid = id
        this.areaId = id
        this.taskVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
      await this.areaDeviceList(id)
    },
    // 获取某个病区下的所有设备
    async areaDeviceList (id) {
      const { data: res } = await this.$http.get('/area-device-list', { params: { id: id } })
      if (res.meta.status === 200) {
        this.deviceList = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 添加新任务
    async addNewTask () {
      this.deviceList = []
      // 获取设备列表
      await this.areaDeviceList(this.areaId)
      this.taskAddItemVisible = true
    },
    // 编辑某一个任务
    async editTask (id) {
      const { data: res } = await this.$http.get('/area-task-item', { params: { id: id } })
      if (res.meta.status === 200) {
        this.editTaskForm.id = id
        this.editTaskForm.title = res.data.title
        this.editTaskForm.timerange = [res.data.start_time * 1000, res.data.end_time * 1000]
        // 将已选择设备列表的ID转为字符串
        res.data.device.forEach(function (value, key, arr) {
          res.data.device[key] = Number(value)
        })
        this.editTaskForm.devcieList = res.data.device
        this.taskEditItemVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 取消提交任务表单
    cancleTask (formName) {
      this.$refs[formName].resetFields()
      this.taskAddItemVisible = false
    },
    // 提交任务表单
    submitAddTask (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/area-task-item', this.addTaskForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.taskSetting(this.addTaskForm.tid)
            this.$refs[formName].resetFields()
            this.taskAddItemVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    // 取消提交编辑任务表单
    cancleEditTask (formName) {
      this.$refs[formName].resetFields()
      this.taskEditItemVisible = false
    },
    // 提交编辑任务表单
    submitEditTask (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/area-task-item', this.editTaskForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.taskSetting(this.addTaskForm.tid)
            this.$refs[formName].resetFields()
            this.taskEditItemVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    // 删除单个任务
    async deleteTaskItem (id) {
      const { data: res } = await this.$http.delete('/area-task-item', { params: { id: id } })
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        await this.taskSetting(this.areaId)
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped>
.area-display{}
.table-pagination{
  padding: 20px 0;
  text-align: center;
}
.page-search{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.page-search .left{
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-search .left .el-button{
  margin-left: 14px;
}
.add-btn-area{
  text-align: right;
  padding: 0 20px 20px 20px;
}
.task-list{
  padding: 20px;
}
.task-list .task-item{
  width: 100%;
  background-color: #e9eaec;
  padding: 10px 0 0 0;
  border-radius: 4px;
  margin-bottom: 20px;
}
.task-list .task-item .title{
  color: #1c2438;
  font-weight: bold;
  margin-left: 10px;
}
.task-list .task-item .time-over{
  color: #495060;
  font-size: 14px;
  margin-top: 6px;
  margin-left: 10px;
}
.task-list .task-item .tools{
  display: flex;
  margin-top: 10px;
}
.task-list .task-item .tools .edit{
  flex: 1;
  background-color: #19be6b;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #FFFFFF;
  font-size: 12px;
  cursor: pointer;
}
.task-list .task-item .tools .edit:hover{
  background-color: #12af60;
}
.task-list .task-item .tools .delete{
  flex: 1;
  background-color: #ed3f14;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #FFFFFF;
  font-size: 12px;
  cursor: pointer;
}
.task-list .task-item .tools .delete:hover{
  background-color: #d33713;
}
.dialog-content {
  padding: 20px;
}
.dialog-content .el-form-item{
  text-align: left;
}
</style>
